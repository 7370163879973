import React, { FC } from 'react';
import styled from 'styled-components';

interface VideoCardProps {
  video: string;
  title: string;
  description: string;
  reviewer: string;
}

const VideoReviewsCard: FC<VideoCardProps> = ({
  video,
  title,
  description,
  reviewer,
}) => (
  <StyledCard>
    <video width="258" height="288" autoPlay playsInline muted loop>
      <source src={video} type="video/mp4" />
    </video>
    <StyledReview>
      <CardTitle>{title}</CardTitle>
      <CardText>{description}</CardText>
      <Name>{reviewer}</Name>
    </StyledReview>
  </StyledCard>
);

export default VideoReviewsCard;

const StyledCard = styled.div`
  width: 16.125rem;

  video {
    object-fit: cover;
    border-radius: 0.625rem 0.625rem 0 0;
  }
`;

const StyledReview = styled.div`
  display: flex;
  width: 100%;
  max-width: 258px;
  min-height: 144px;
  padding: 1rem 0.75rem;
  flex-direction: column;
  border-radius: 0 0 0.625rem 0.625rem;
  background: #f6f4ee;
`;

const CardTitle = styled.h4`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  padding-bottom: 0.375rem;
`;

const CardText = styled.p`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-bottom: 0.75rem;
`;

const Name = styled.p`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
`;
