import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { videoReviews } from 'utils/videoReviews';

const VideoReviewsSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>Real Walking Yoga reviews on video</Title>
      <Subtitle>Proven results from happy users</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {videoReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <VideoReviewsCard
                key={index}
                video={review.url}
                title={review.title}
                description={review.description}
                reviewer={review.name}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default VideoReviewsSection;

const Container = styled.section`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 2.375rem 1rem;
  }
`;

const Title = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.25rem;
`;

const Subtitle = styled.p`
  color: #555770;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 2.375rem;
  @media ${tablet} {
    padding-bottom: 1.25rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 260px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #e1fcda;
    &-active {
      background-color: #3fa134;
      width: 8px;
    }
  }
`;
