import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import Full from 'assets/icons/full-orange-stars.svg';

const cardData = [
  {
    review:
      'Exercise plan in the app is great, it also has a lot of features like a personalized meal plan and trackers.<br/> Love it!!!!!',
    name: 'Alex P.',
  },
  {
    review:
      'I\'m so glad I found this app when I did. Helped me lose weight. The app is really simple to use.',
    name: 'Paola S.',
  },
  {
    review:
      'Got this plan because I wanted to start doing yoga. It’s very fun and easy, but I noticed I’m losing weight, too.',
    name: 'Ruby G.',
  },
  {
    review:
      'I\'ve been going through a rough patch, and Walking Yoga has been incredibly therapeutic.',
    name: 'Sarah J.',
  },
  {
    review:
      'The stress reduction and gentle exercise help me wind down in the evening. I wake up feeling refreshed.',
    name: 'Susan',
  },
  {
    review:
      'Training course in the book is great, it also has a lot of features to get to know and understand your puppy better. Love it!!!!!',
    name: 'Alameda',
  },
];

const SatisfiedUsersSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>10,000+ users</Title>
      <Subtitle>Listen to what they have to say</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <Inner>
                  <Name>{review.name}</Name>
                  <Full />
                </Inner>
                <Review dangerouslySetInnerHTML={{ __html: review.review }} />
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default SatisfiedUsersSection;

const Inner = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Review = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const Name = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #f6f4ee;
  @media ${tablet} {
    padding: 2.75rem 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.75rem;
  border: 1px solid #f9f9f9;
  background: #fff;
  padding: 1rem;
  max-width: 21.4375rem;
  width: 100%;
  overflow: hidden;
  height: 11.125rem;
  @media ${tablet} {
    max-width: 17.5rem;
  }
`;

const Title = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.25rem;
`;

const Subtitle = styled.p`
  color: #555770;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 2.375rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 63.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 21.4375rem !important;
    margin-bottom: 28px;

    @media ${tablet} {
      width: 17.25rem !important;
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #fff;
    &-active {
      background-color: #3fa134;
      width: 8px;
    }
  }
`;
