import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
// import Full from 'assets/icons/full-trustpilot.svg';
import { DynamicImage } from 'components/images/DynamicImage';
// import Verified from 'assets/icons/verified.svg';
import { useRouter } from 'apis/history';
import PrimaryButton from 'components/PrimaryButton';
import Full from 'assets/icons/full-orange-stars.svg';
import Verified from 'assets/icons/verified-reviews.svg';

const cardData = [
  {
    img: 'reviews/r2.png',
    review:
      'Walking Yoga offers an approach to wellness that I haven\'t found in other apps. The guided yoga walks are wonderful for both body and mind.',
    name: 'Rebecca L., 25',
  },
  {
    img: 'reviews/r3.png',
    review:
      'The combination of walking and yoga helps me clear my mind and reduce anxiety. It\'s like having a personal wellness coach in my pocket.',
    name: 'Jennie W., 45',
  },
  {
    img: 'reviews/r4.png',
    review:
      'I’ve been using Walking Yoga for a month now, and the results are impressive. My stress has decreased significantly, and I\'ve lost a 11 pounds.',
    name: 'Patrice K., 41',
  },
  {
    img: 'reviews/r5.png',
    review:
      'As a busy mom, finding time for myself is hard. Walking Yoga has been a lifesaver! I can fit in quick sessions between chores and feel so much more relaxed and energized.',
    name: 'Joanne K., 41',
  },
  {
    img: 'reviews/r6.png',
    review:
      'I love being outdoors, and Walking Yoga combines my love for nature with yoga. It\'s been amazing for my mental clarity and physical fitness. It\'s a perfect way to start my day.',
    name: 'Trisha S., 27',
  },
  {
    img: 'reviews/r7.png',
    review:
      'Walking Yoga plan is A MA ZING! It’s super easy to follow, and I am enjoying my journey so much. I’m losing weight and feeling so much healthier every day. Happy I found walking yoga when I did.',
    name: 'Viv L., 24',
  },
];

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();
  const { goToLanding } = useRouter();
  return (
    <Container>
      <Title>Carnimeat success stories</Title>
      <Subtitle>Why Carnimeat? Let our customers tell you.</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <DynamicImageStyled src={review.img} alt="" />
                <Inner>
                  <CardText>{review.review}</CardText>
                  <BottomContainer>
                    <BottomInner>
                      <Name>{review.name}</Name>
                      <Full />
                    </BottomInner>
                    <Verified />
                  </BottomContainer>
                </Inner>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>

      <StyledButton onClick={() => goToLanding()}>
        Start your journey
      </StyledButton>
    </Container>
  );
};

export default SuccessStoriesSection;

const DynamicImageStyled = styled(DynamicImage)`
  height: 21.25rem;
`;

const StyledButton = styled(PrimaryButton)`
  max-width: 21.4375rem;
  margin: 2.5rem auto 0;
  @media ${tablet} {
    margin: 2rem auto 0;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 2.75rem 1rem;
  }
`;
const Title = styled.h3`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.25rem;
`;

const Subtitle = styled.p`
  color: #555770;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 2.375rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 63.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 21.4375rem !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #e1fcda;
    &-active {
      background-color: #3fa134;
      width: 8px;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 37.625rem;
  padding: 1.5rem 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0.625rem;
  border: 1px solid #f6f4ee;
  background: #f6f4ee;
  max-width: 21.4375rem;
  width: 100%;
  margin: 0 0 1.5rem;
  overflow: hidden;
  @media ${tablet} {
    margin: 0 0 2rem 0;
  }
`;

const CardText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 0.75rem;
  height: 7.625rem;
  @media ${tablet} {
  }
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1.25rem;
  border-top: 1px solid #e6e5e1;
  @media ${tablet} {
  }
`;

const Name = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
`;

const BottomInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
`;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1.25rem;
  justify-content: space-between;
`;
