interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353489/Tia_2.mp4',
    title: 'I’m amazed',
    description: `The combination of gentle yoga and walking is perfect. It's now a part of my daily routine, and I feel so much calmer and happier. Truly a fantastic app!`,
    name: 'Angela P.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353489/Tia.mp4',
    title: 'Almost 2 months in',
    description: `I’ve been doing Walking Yoga for about six weeks, and the results have been incredible. Not only have I lost weight, but my posture and flexibility have improved!`,
    name: 'Pauline C.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353488/Guoste.mp4',
    title: 'great combo',
    description: `As someone who doesn’t like intense workouts, this plan was perfect for me. Walking combined with yoga felt really natural for me.`,
    name: 'Sandra P.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353488/Tami_joga_namie.mp4',
    title: 'Lost 10lbs',
    description: `I’ve lost 10 pounds over two months, but beyond weight loss, my stress levels are lower, and I’ve noticed a real improvement in my overall well-being.`,
    name: 'Alex M.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353488/Maria.mp4',
    title: 'Amazing results!',
    description: `Lost 20lbs in 2 months just by walking and a little bit of easy yoga. I can’t believe what I see when I step on the scales or look at the mirror. Great app!`,
    name: 'Tiffany L.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1727353487/Lizzy.mp4',
    title: 'Can’t wait for my walks',
    description: `At first, I wasn’t sure how walking and yoga together would help with weight loss, but wow! Gentle stretching and walking worked wonders for my body.`,
    name: 'Monica B.',
  },
];
