import React, { FC } from 'react';
import styled from 'styled-components';
import Trustpilot from 'assets/icons/trustpilot-rating.svg';
import OrangeStarts from 'assets/icons/orange-stars.svg';
import Verified from 'assets/icons/verified.svg';
import ReviewCard from 'components/reviews/ReviewCard';
import TrustpilotStars from 'components/reviews/TrustpilotStars';
import TrustpilotStarsPartial from 'components/reviews/TrustpilotStarsPartial';
import CtaButton from 'components/reviews/CtaButton';

import { tablet } from 'styles/breakpoints';
import Text from 'components/Text';
import PrimaryButton from 'components/PrimaryButton';
import { useRouter } from 'apis/history';
import { DynamicImage } from 'components/images/DynamicImage';

const MainReviewSection: FC = () => {
  const { goToLanding } = useRouter();
  return (
    <Container>
      <Title>Walking Yoga reviews</Title>
      <Subtitle>Thousands of happy users all over the world</Subtitle>
      <Trustpilot />
      <Card>
        <DynamicImage src="reviews/r1.png" alt="" />
        <CardText>
          “I love it!! This app has transformed my body & my mind. It helped me
          build a truly healthy lifestyle! I feel and look like a new person.”
        </CardText>

        <BottomContainer>
          <BottomInner>
            <Name>Rachel W., 31</Name>
            <OrangeStarts />
          </BottomInner>
          <Verified />
        </BottomContainer>
      </Card>
      <StyledButton onClick={() => goToLanding()}>
        Start your journey
      </StyledButton>
    </Container>
  );
};

export default MainReviewSection;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #f6f4ee;
  @media ${tablet} {
    padding: 2.375rem 1rem 3.75rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
`;

const Subtitle = styled(Text)`
  color: #555770;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding: 0.25rem 0 0.625rem;
`;

const StyledButton = styled(PrimaryButton)`
  max-width: 21.375rem;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 1px solid #eeeef0;
  background: #fff;
  max-width: 21.375rem;
  width: 100%;
  margin: 1.5rem 0 1.25rem;
  @media ${tablet} {
    margin: 1.25rem 0 1rem;
  }
`;

const CardText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding: 0.75rem 0 1.25rem;
  border-bottom: 1px solid rgba(142, 144, 154, 0.15);
  @media ${tablet} {
    padding: 1.25rem 0 1.5rem;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const Name = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.00875rem;
`;

const BottomInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
`;
